<template>
  <div class="margen_total">
    <!-- Mensaje para el caso que no se haya seleccionado ningún supervisor. -->
    <p class="letter" v-if="!selectedSup && userRole !== 'manager'">
      Selecciona un supervisor para visualizar los datos.
    </p>

    <!-- Spinner de carga mientas los datos no se pueden visualizar. -->
    <div v-else-if="!dataLoaded" class="loading-spinner">
      <i class="fa-solid fa-spinner fa-spin"></i>
      <div class="spinner"></div>
    </div>
  </div>
  <!-- Se utiliza el componente Tabla para mostrar la información respecto a la flota asociada al cliente. -->
  <!-- Posteriormente se define y se entregan todos los parámetros necesarios para que la tabla se visualice correctamente. -->
  <div class="clientes-table-container" v-if="dataLoaded">
    <Tabla
      v-if="clientesInfo.length > 0"
      :Info="clientesInfo"
      :columnHeaders="[
        'Rut',
        'Cliente',
        'Ejecutivo',
        'Flota Actual',
        'Reemplazos Activos',
        'F/S',
        '% F/S',
        'Contratos por vencer',
        'Siniestros',
        '% Siniestros',
        'Robos y PT',
      ]"
      :rowFields="[
        { key: 'Cedula', format: 'text' },
        { key: 'NomComp', format: 'text' },
        { key: 'Ejecutivo', format: 'text' },
        { key: 'Vehiculos_Activos', format: 'number' },
        { key: 'Reemplazos_Activos', format: 'number' },
        { key: 'Unidades_Fuera_Servicio', format: 'number' },
        { key: 'Porcentaje_Unidades_Fuera_Servicio', format: 'number' },
        { key: 'Contratos_Proximos_Vencer', format: 'number' },
        { key: 'Siniestros_ultimo_ano', format: 'number' },
        { key: 'Porcentaje_siniestros', format: 'number' },
        { key: 'Robos_Perdidas_Totales', format: 'number' },
      ]"
      tableTitle="CLIENTES"
      :numColspan="11"
      :subnumColspan="6"
      :dataColspan="6"
      :enablePopup="false"
      :enableFilters="[
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ]"
    />
    <!-- En caso de que no existan datos, la vista mostrará al usuario que dicho cliente no tiene una flota asociada. -->
    <p v-else>No hay información disponible.</p>
  </div>
</template>

<script>
// Se importan las librerías necesarias para el correcto funcionamiento de la vista.
import { ref, computed, watch } from "vue";
import Tabla from "../components/Tabla_botones.vue";

import { useSupStore } from "../stores/supStore.js";
// Se importa la tienda de usuario.
import useUserStore from "../stores/userStore.js";

import {
  getVistaClientesGeneral,
  getVistaClientesSupervisor,
} from "../api/vistacliente.js";

export default {
  name: "ClientesPage",
  components: { Tabla },
  setup() {
    // Se definen las variables y funciones necesarias para el correcto funcionamiento de la vista.
    const clientesInfo = ref([]);

    // Se obtienen los datos de la tienda de supervisor y usuario.
    const supStore = useSupStore();
    const userStore = useUserStore();

    // Se definen las variables necesarias para el correcto funcionamiento de la vista.
    const userRole = computed(() => userStore.user?.rol);
    const userId = userStore.user?.id || null;
    const selectedSup = computed(() => supStore.selectedSup);
    const dataLoaded = ref(false);

    // Se define la función loadData, la cual se encarga de cargar los datos necesarios para la vista.
    const loadData = async () => {
      dataLoaded.value = false;

      try {
        // Se inicializa la variable Sup_Cod_Cartera en null.
        let Sup_Cod_Cartera = null;

        // Se verifica el rol del usuario para asignar el valor correspondiente a la variable Sup_Cod_Cartera.
        if (userRole.value === "manager") {
          Sup_Cod_Cartera = userId;
        } else if (selectedSup.value && selectedSup.value.unikey_SU) {
          Sup_Cod_Cartera = selectedSup.value.unikey_SU;
        } else {
          dataLoaded.value = true;
          return;
        }

        // Caso en que el id del supervisor es 100 (administrador).
        if (Sup_Cod_Cartera === 100) {
          // Se crea la constante fecha, para almacenar la fecha actual cuando se realiza la consulta.
          const fecha = new Date().toISOString().split("T")[0];

          // Se crea la constante correspondiente al caso.
          const caso = "General";

          // Se realiza la consulta a la API para obtener los datos de los CPA pendientes del cliente seleccionado.
          const response = await getVistaClientesGeneral(
            fecha,
            caso,
            Sup_Cod_Cartera
          );

          // Se asignan los valores obtenidos a las variables correspondientes.
          const reporteClientes = response.datareportevistaclientesgeneral;

          // Si la el segundo dataset (datacpa) es un array va llenando la tabla con la información.
          if (
            reporteClientes &&
            Array.isArray(reporteClientes.datavistaclientesgeneral)
          ) {
            clientesInfo.value = reporteClientes.datavistaclientesgeneral.map(
              (item) => ({
                ...item,
              })
            );
          }

          // Caso en que el id del supervisor no es 100 (supervisor específico).
        } else if (Sup_Cod_Cartera !== 100) {
          // Se crea la constante fecha, para almacenar la fecha actual cuando se realiza la consulta.
          const fecha = new Date().toISOString().split("T")[0];

          // Se crea la constante correspondiente al caso.
          const caso = "Supervisor";

          // Se realiza la consulta a la API para obtener los datos de los CPA pendientes del cliente seleccionado.
          const response = await getVistaClientesSupervisor(
            fecha,
            caso,
            Sup_Cod_Cartera
          );

          // Se asignan los valores obtenidos a las variables correspondientes.
          const reporteClientes = response.datareportevistaclientessupervisor;

          // Si la el segundo dataset (datacpa) es un array va llenando la tabla con la información.
          if (
            reporteClientes &&
            Array.isArray(reporteClientes.datavistaclientessupervisor)
          ) {
            clientesInfo.value =
              reporteClientes.datavistaclientessupervisor.map((item) => ({
                ...item,
              }));
          }
        }

        // Una vez que se obtienen los datos, se marca como cargados (true).
        dataLoaded.value = true;
      } catch (error) {
        // Marca los datos como cargados incluso si hay un error para evitar el estado de carga infinito.
        console.error("Error al cargar datos:", error);
        dataLoaded.value = true;
      }
    };

    // Se utiliza watch para ejecutar la función loadData cada vez que se cambie el supervisor seleccionado o el rol del usuario.
    watch(
      [selectedSup, userRole],
      ([newSup, role]) => {
        if (newSup || role === "manager") {
          loadData();
        }
      },
      { immediate: true }
    );

    // Se retorna un objeto con las variables y funciones necesarias para el correcto funcionamiento de la vista.
    return {
      clientesInfo,
      dataLoaded,
      selectedSup,
      userRole,
    };
  },
};
</script>

<!-- css para el ícono de carga y la tabla. -->
<style scoped>
@import "../css/clientes.css";
</style>
