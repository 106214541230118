<template>
  <div class="margen_total">
    <div v-if="!dataLoaded" class="loading-spinner">
      <i class="fa-solid fa-spinner fa-spin"></i>
      <div class="spinner"></div>
    </div>
    <!-- Se utiliza el componente Tabla para mostrar la información de los CPA pendientes asociados al cliente. -->
    <!-- Se utiliza un condicional para que se muestre la información en caso de que existan datos. -->
    <!-- Posteriormente se define y se entregan todos los parámetros necesarios para que la tabla se visualice correctamente. -->
    <Tabla
      v-if="dataLoaded && cpaInfo.length > 0"
      :Info="cpaInfo"
      :subnum1="cpapendientes"
      :subnum2="cpapendientes3meses"
      :enablePopup="false"
      :columnHeaders="[
        'Código CPA',
        'PPU',
        'CPA',
        'Cargos',
        'Fecha Apertura',
        'Días Aperturado',
      ]"
      :rowFields="[
        { key: 'CODIGO_CPA', format: 'number' },
        { key: 'PPU', format: 'text' },
        { key: 'CPA', format: 'currency' },
        { key: 'Cargos', format: 'currency' },
        { key: 'FECHA_APERTURA', format: 'date' },
        { key: 'DIAS_APERTURADO', format: 'number' },
      ]"
      :enableFilters="[true, true, true, true, true, true]"
      tableTitle="CPA pendientes"
      subtable1="# CPA pendientes"
      subtable2="# CPA sobre 3 meses"
      numColspan="6"
      subnumColspan="3"
      dataColspan="3"
      :disabled="false"
    />
    <!-- En caso de que no existan datos, la vista mostrará al usuario que dicho cliente no tiene CPA pendientes asociados. -->
    <p v-if="dataLoaded && cpaInfo.length === 0">
      El cliente {{ selectedClient.name_CL }} no presenta CPA pendientes.
    </p>
  </div>
</template>

<script>
// Se importan los elementos necesarios para el correcto funcionamiento de la vista CPA.
import { ref, computed, onMounted } from "vue";
// Se importa el componente Tabla para mostrar la información obtenida a través de la consulta a la API.
import Tabla from "../components/Tabla_botones.vue";
// Se importan las tiendas para obtener los datos del cliente y supervisor seleccionados.
import { useClientStore } from "../stores/clientStore.js";
import { useSupStore } from "../stores/supStore.js";
// Se importa la tienda de usuario.
import useUserStore from "../stores/userStore.js";
// Se importan las funciones para guardar y verificar el almacenamiento de las consultas.

// Se importa la función para realizar la llamada al backend y obtener los datos de los CPA de la base de datos.
import { getReporteCPA } from "../api/cpa.js";

export default {
  // Se definen los componentes que se utilizarán en la vista.
  components: {
    Tabla,
  },
  setup() {
    // Se definen las variables y funciones necesarias para el correcto funcionamiento de la vista.
    const cpaInfo = ref([]);
    const cpapendientes = ref(null);
    const cpapendientes3meses = ref(null);
    const dataLoaded = ref(false);

    // Se definen las tiendas para obtener el cliente y supervisor seleccionados.
    const clientStore = useClientStore();
    const supStore = useSupStore();
    const userStore = useUserStore();

    // Se crean las variables selectedSup y selectedClient que permitirán obtener el supervisor y el cliente seleccionado.
    const selectedClient = computed(() => clientStore.selectedClient);
    const selectedSup = computed(() => supStore.selectedSup);

    // Se define la función loadData, la cual se encarga de obtener los datos necesarios para mostrar la información de los CPA.
    const loadData = async () => {
      try {
        // Se utiliza la tienda de usuarios para obtener el rol y el id del usuario.
        const userRole = userStore.user?.role;
        const userId = userStore.user?.id;

        // Se inicializa la variable Sup_Cod_Cartera en null.
        // Se establece el valor de Sup_Cod_Cartera según el rol del usuario.
        let Sup_Cod_Cartera = null;
        if (userRole === "manager") {
          Sup_Cod_Cartera = userId;
        } else if (selectedSup.value && selectedSup.value.unikey_SU) {
          Sup_Cod_Cartera = selectedSup.value.unikey_SU;
        }

        // Se establece el valor de Numero_Cliente según el cliente seleccionado.
        const Numero_Cliente = selectedClient.value.unikey_CL;

        // Se define el caso según los valores de Sup_Cod_Cartera y Numero_Cliente.
        let caso;
        if (Sup_Cod_Cartera === 100 && Numero_Cliente === 100) {
          caso = "General-General";
        } else if (Sup_Cod_Cartera !== 100 && Numero_Cliente === 100) {
          caso = "General-Supervisor";
        } else if (Sup_Cod_Cartera === 100 && Numero_Cliente !== 100) {
          caso = "General-Cliente";
        } else {
          caso = "Supervisor-Cliente";
        }
        // Se busca obtener los CPA pendientes generales (de todos los clientes LOP).

        // Se crea la constante fecha, para almacenar la fecha actual cuando se realiza la consulta.
        const fecha = new Date().toISOString().split("T")[0];

        // Se definen las variables SupId y ClienteId.
        const SupId = Sup_Cod_Cartera;
        const ClienteId = selectedClient.value.unikey_CL;

        // Se realiza la consulta a la API para obtener los datos de los CPA pendientes del cliente seleccionado.
        const response = await getReporteCPA(fecha, caso, SupId, ClienteId);

        // Se asignan los valores obtenidos a las variables correspondientes.
        const reporteCPA = response.datareportecpa;
        cpapendientes.value = reporteCPA?.CPA_PENDIENTES || 0;
        cpapendientes3meses.value = reporteCPA?.CPA_PENDIENTES_3_MESES || 0;

        // Si la el segundo dataset (datacpa) es un array va llenando la tabla con la información.
        if (reporteCPA && Array.isArray(reporteCPA.datacpa)) {
          cpaInfo.value = reporteCPA.datacpa.map((item) => ({
            ...item,
            CPA: formatCurrency(item["CPA"]),
            Cargos: formatCurrency(item["Cargos"]),
            FECHA_APERTURA: formatDate(item.FECHA_APERTURA),
            DIAS_APERTURADO: formatNumber(item["DIAS_APERTURADO"]),
          }));
        }

        // Se establece el valor de dataLoaded en true.
        dataLoaded.value = true;
      } catch (error) {
        console.error("Error al obtener la información:", error);
        dataLoaded.value = true;
      }
    };

    // Se definen las funciones de formateo de datos.

    // Función para formatear números.
    const formatNumber = (value) => {
      if (value === "-" || value === 0 || value === "0" || value === "N/A") {
        return "0";
      }
      if (value === "OT Abierta") {
        return value;
      }
      return new Intl.NumberFormat().format(value);
    };

    // Función para formatear fechas.
    const formatDate = (value) => {
      if (value === "Pendiente" || !value) {
        return value || "N/A";
      }
      return new Date(value).toLocaleDateString("es-CL");
    };

    // Función para formatear dinero.
    const formatCurrency = (value) => {
      if (value === "Sin costo asociado" || value === "Sin cargo asociado") {
        return value;
      }
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }).format(value);
    };

    // Se llama a loadData cuando se monta el componente, solo si hay un supervisor seleccionado (utilizando onMounted).
    onMounted(loadData);

    // Se retornan los elementos necesarios para la vista CPA.
    return {
      cpaInfo,
      cpapendientes,
      cpapendientes3meses,
      dataLoaded,
      selectedClient,
      selectedSup,
    };
  },
};
</script>

<!-- css para el ícono de carga. -->
<style scoped>
@import "../css/spinner.css";
</style>
