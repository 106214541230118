<template>
  <div class="layout-container">
    <div class="sidemenu-container">
      <SideMenu class="home-sidemenu" />
      <ButtonsMenu class="home-menu" />
    </div>

    <!-- Contenedor principal para las tablas. -->
    <div class="main-container">
      <div class="content-columns">
        <div class="buttons-container">
          <!-- Botones (flota, fuera de servicio, mantenciones y cpa). -->
          <Boton
            icon="fa-solid fa-car-side"
            primaryText="Flota"
            secondaryText="Flota general, unidades nuevas y unidades pre-LOP."
            to="/FlotaUnidadesNuevasPreLOP"
            :disabled="!isButtonEnabled"
            :class="{ disabled: !isButtonEnabled }"
          />
          <Boton
            icon="fa-solid fa-car-battery"
            primaryText="Servicios"
            secondaryText="Reemplazos activos y unidades fuera de servicio."
            to="/ReemplazosFueraServicio"
            :disabled="!isButtonEnabled"
            :class="{ disabled: !isButtonEnabled }"
          />
          <Boton
            icon="fa-solid fa-wrench"
            primaryText="Mantenciones - Revisiones"
            secondaryText="Mantenciones pendientes y revisiones técnicas/gases pendientes."
            to="/MantencionesyRevisionesPendientes"
            :disabled="!isButtonEnabled"
            :class="{ disabled: !isButtonEnabled }"
          />
          <Boton
            icon="fa-solid fa-dollar-sign"
            primaryText="CPA"
            secondaryText="CPA pendientes."
            to="/Cpa"
            :disabled="!isButtonEnabled"
            :class="{ disabled: !isButtonEnabled }"
          />
        </div>

        <!-- Tabla CPA. -->
        <div class="tables-container">
          <div class="table-wrapper">
            <Tabla
              tableTitle="% CPA menor a 3 meses"
              :subnum1="
                metrica_cpa_porcentaje === 'no data'
                  ? 'cliente no tiene información de CPA menores a 3 meses disponible'
                  : metrica_cpa_porcentaje + '%'
              "
              :disabled="true"
              :enablePopup="true"
              modalTableTitle="% CPA menor a 3 meses"
              :modalSubtable1="'# CPA pendientes menores a 3 meses'"
              :modalSubtable2="'% CPA menor a 3 meses'"
              :modalSubnum1="metrica_cpa_num || 0"
              :modalSubnum2="metrica_cpa_porcentaje + '%' || '0%'"
              :modalNumColspan="7"
              :modalSubnumColspan="4"
              :modalDataColspan="4"
              :modalData="cpaInfo"
              :modalHeaders="[
                'Cliente',
                'Código CPA',
                'PPU',
                'CPA',
                'Cargos',
                'Fecha Apertura',
                'Días Aperturado',
              ]"
              :modalRowFields="[
                { key: 'CLIENTE', format: 'text' },
                { key: 'CODIGO_CPA', format: 'number' },
                { key: 'PPU', format: 'text' },
                { key: 'CPA', format: 'text' },
                { key: 'Cargos', format: 'text' },
                { key: 'FECHA_APERTURA', format: 'date' },
                { key: 'DIAS_APERTURADO', format: 'number' },
              ]"
              :modalExportDisabled="false"
              :modalEnableFilters="[true, true, true, true, true, true, true]"
            />
          </div>

          <!-- Tabla Mantenciones por KM. -->
          <div class="table-wrapper">
            <Tabla
              tableTitle="% Mantenciones por KM pendientes"
              :subnum1="
                metrica_mantenciones4d_porcentaje === 'no data'
                  ? 'cliente no tiene información de mantenciones por KM pendientes disponible'
                  : metrica_mantenciones4d_porcentaje + '%'
              "
              :disabled="true"
              :enablePopup="true"
              modalTableTitle="% Mantenciones por KM pendientes"
              :modalSubtable1="'# Unidades con mantenciones por KM pendientes'"
              :modalSubtable2="'% Mantenciones por KM pendientes'"
              :modalSubnum1="metrica_mantenciones4d_num || 0"
              :modalSubnum2="metrica_mantenciones4d_porcentaje + '%' || '0%'"
              :modalNumColspan="9"
              :modalSubnumColspan="5"
              :modalDataColspan="5"
              :modalData="mantenciones4dInfo"
              :modalHeaders="[
                'Cliente',
                'PPU',
                'Codigo',
                'Región',
                'KM 4D',
                'KM MP',
                'KM Prox MP',
                'Fecha MP',
                'Exceso 4D',
              ]"
              :modalRowFields="[
                { key: 'CLIENTE', format: 'text' },
                { key: 'PPU', format: 'text' },
                { key: 'CODIGO', format: 'text' },
                { key: 'REGION', format: 'text' },
                { key: 'KM_4D', format: 'number' },
                { key: 'KM_MP', format: 'number' },
                { key: 'KM_PROX_MP', format: 'number' },
                { key: 'FECHA_MP', format: 'date' },
                { key: 'EXCESO_4D', format: 'number' },
              ]"
              :modalExportDisabled="false"
              :modalEnableFilters="[
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
              ]"
            />
          </div>

          <!-- Tabla Mantenciones por Día. -->
          <div class="table-wrapper">
            <Tabla
              tableTitle="% Mantenciones por tiempo pendientes"
              :subnum1="
                metrica_mantencionesest_porcentaje === 'no data'
                  ? 'cliente no tiene información de mantenciones por tiempo pendientes disponible'
                  : metrica_mantencionesest_porcentaje + '%'
              "
              :disabled="true"
              :enablePopup="true"
              modalTableTitle="% Mantenciones por tiempo pendientes"
              :modalSubtable1="'# Unidades con mantenciones por tiempo pendientes'"
              :modalSubtable2="'% Mantenciones por tiempo pendientes'"
              :modalSubnum1="metrica_mantencionesest_num || 0"
              :modalSubnum2="metrica_mantencionesest_porcentaje + '%' || '0%'"
              :modalNumColspan="10"
              :modalSubnumColspan="5"
              :modalDataColspan="5"
              :modalData="mantencionesestInfo"
              :modalHeaders="[
                'Cliente',
                'PPU',
                'Codigo',
                'Región',
                'KM 4D',
                'KM EST',
                'KM MP',
                'KM Prox MP',
                'Fecha MP',
                'Exceso EST',
              ]"
              :modalRowFields="[
                { key: 'CLIENTE', format: 'text' },
                { key: 'PPU', format: 'text' },
                { key: 'CODIGO', format: 'text' },
                { key: 'REGION', format: 'text' },
                { key: 'KM_4D', format: 'number' },
                { key: 'KMS_EST', format: 'number' },
                { key: 'KM_MP', format: 'number' },
                { key: 'KM_PROX_MP', format: 'number' },
                { key: 'FECHA_MP', format: 'date' },
                { key: 'EXCESO_EST', format: 'number' },
              ]"
              :modalExportDisabled="false"
              :modalEnableFilters="[
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
              ]"
            />
          </div>

          <!-- Tabla Revisiones Técnicas. -->
          <div class="table-wrapper">
            <Tabla
              tableTitle="% Revisiones técnicas pendientes"
              :subnum1="
                metrica_revisionestecnicas_porcentaje === 'no data'
                  ? 'cliente no tiene información de revisiones técnicas pendientes disponible'
                  : metrica_revisionestecnicas_porcentaje + '%'
              "
              :disabled="true"
              :enablePopup="true"
              modalTableTitle="% Revisiones técnicas pendientes"
              :modalSubtable1="'# Unidades con revisiones técnicas pendientes'"
              :modalSubtable2="'% Revisiones técnicas pendientes'"
              :modalSubnum1="metrica_revisionestecnicas_num || 0"
              :modalSubnum2="
                metrica_revisionestecnicas_porcentaje + '%' || '0%'
              "
              :modalNumColspan="5"
              :modalSubnumColspan="3"
              :modalDataColspan="3"
              :modalData="revisionestecnicasInfo"
              :modalHeaders="[
                'Cliente',
                'PPU',
                'Codigo',
                'Ubicación',
                'Rev. Técnica',
              ]"
              :modalRowFields="[
                { key: 'CLIENTE', format: 'text' },
                { key: 'PPU', format: 'text' },
                { key: 'CODIGO', format: 'text' },
                { key: 'REGION', format: 'text' },
                { key: 'Rev_TECNICA', format: 'date' },
              ]"
              :modalExportDisabled="false"
              :modalEnableFilters="[true, true, true, true, true]"
            />
          </div>

          <!-- Tabla Revisiones Gases. -->
          <div class="table-wrapper">
            <Tabla
              tableTitle="% Revisiones gases pendientes"
              :subnum1="
                metrica_revisionesgases_porcentaje === 'no data'
                  ? 'cliente no tiene información de revisiones de gases pendientes disponible'
                  : metrica_revisionesgases_porcentaje + '%'
              "
              :disabled="true"
              :enablePopup="true"
              modalTableTitle="% Revisiones gases pendientes"
              :modalSubtable1="'# Unidades con revisiones de gases pendientes'"
              :modalSubtable2="'% Revisiones gases pendientes'"
              :modalSubnum1="metrica_revisionesgases_num || 0"
              :modalSubnum2="metrica_revisionesgases_porcentaje + '%' || '0%'"
              :modalNumColspan="5"
              :modalSubnumColspan="3"
              :modalDataColspan="3"
              :modalData="revisionesgasesInfo"
              :modalHeaders="[
                'Cliente',
                'PPU',
                'Codigo',
                'Ubicación',
                'Rev. Gases',
              ]"
              :modalRowFields="[
                { key: 'CLIENTE', format: 'text' },
                { key: 'PPU', format: 'text' },
                { key: 'CODIGO', format: 'text' },
                { key: 'REGION', format: 'text' },
                { key: 'Rev_GASES', format: 'date' },
              ]"
              :modalExportDisabled="false"
              :modalEnableFilters="[true, true, true, true, true]"
            />
          </div>

          <!-- Tabla Reemplazos. -->
          <div class="table-wrapper">
            <Tabla
              tableTitle="Promedio días reemplazo cierre menor a 5 días"
              :subnum1="
                metrica_reemplazos_porcentaje === 'no data'
                  ? 'cliente no tiene información de reeemplazos disponible'
                  : metrica_reemplazos_porcentaje + ' Días'
              "
              :disabled="true"
              :enablePopup="true"
              modalTableTitle="Promedio días reemplazo cierre menor a 5 días"
              :modalSubtable1="'# Unidades'"
              :modalSubtable2="'Promedio días'"
              :modalSubnum1="metrica_reemplazos_num || 0"
              :modalSubnum2="metrica_reemplazos_porcentaje + ' Días' || 0"
              :modalNumColspan="10"
              :modalSubnumColspan="5"
              :modalDataColspan="5"
              :modalData="reemplazosInfo"
              :modalHeaders="[
                'Cliente',
                'PPU Reemplazo',
                'Código Reemplazo',
                'PPU Titular',
                'Código Titular',
                'Status Titular',
                'Región',
                'Fecha Reemplazo',
                'Días Reemplazo',
                'Días Cierre OT',
              ]"
              :modalRowFields="[
                { key: 'Cliente', format: 'text' },
                { key: 'PPU_REEMPLAZO', format: 'text' },
                { key: 'CODIGO_REEMPLAZO', format: 'text' },
                { key: 'PPU_ORIGINAL', format: 'text' },
                { key: 'CODIGO_ORIGINAL', format: 'text' },
                { key: 'STATUS_ORIGINAL', format: 'text' },
                { key: 'REGION', format: 'text' },
                { key: 'FECHA_REEMPLAZO', format: 'date' },
                { key: 'DIAS_REEMPLAZO', format: 'number' },
                { key: 'Dias_Cierre_OT', format: 'number' },
              ]"
              :modalExportDisabled="false"
              :modalEnableFilters="[
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
              ]"
            />
          </div>

          <!-- Tabla Unidades Nuevas. -->
          <div class="table-wrapper">
            <Tabla
              tableTitle="Promedio días LOP nuevo sin contrato"
              :subnum1="
                metrica_unidadesnuevas_promedio === 'no data'
                  ? 'cliente no tiene información de unidades nuevas disponible'
                  : metrica_unidadesnuevas_promedio + ' Días'
              "
              :disabled="true"
              :enablePopup="true"
              modalTableTitle="Promedio días LOP nuevo sin contrato"
              :modalSubtable1="'# Unidades'"
              :modalSubtable2="'Promedio días'"
              :modalSubnum1="metrica_unidadesnuevas_num || 0"
              :modalSubnum2="metrica_unidadesnuevas_promedio || 0"
              :modalNumColspan="8"
              :modalSubnumColspan="4"
              :modalDataColspan="4"
              :modalData="unidadesnuevasInfo"
              :modalHeaders="[
                'Cliente',
                'PPU',
                'Codigo',
                'Region',
                'Ubicación',
                'Fecha Disponible',
                'Fecha Alquilado',
                'Días Disponible',
              ]"
              :modalRowFields="[
                { key: 'CLIENTE', format: 'text' },
                { key: 'Patente', format: 'text' },
                { key: 'CODIGO', format: 'text' },
                { key: 'Region', format: 'text' },
                { key: 'Ubicacion', format: 'text' },
                { key: 'FECHA_DISPONIBLE', format: 'date' },
                { key: 'FECHA_ALQUILADO', format: 'date' },
                { key: 'DIAS_DISPONIBLE', format: 'number' },
              ]"
              :modalExportDisabled="false"
              :modalEnableFilters="[
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
              ]"
            />
          </div>

          <!-- Tabla Próximos vencimientos. -->
          <div class="table-wrapper">
            <Tabla
              tableTitle="% Próximos vencimientos de contratos"
              :subnum1="
                metrica_proximosvencimientos_porcentaje === 'no data'
                  ? 'cliente no tiene información de próximos vencimientos disponible'
                  : metrica_proximosvencimientos_porcentaje + '%'
              "
              :disabled="true"
              :enablePopup="true"
              modalTableTitle="% Próximos vencimientos de contratos"
              :modalSubtable1="'# Próximos vencimientos de contratos'"
              :modalSubtable2="'% Próximos vencimientos de contratos'"
              :modalSubnum1="metrica_proximosvencimientos_num || 0"
              :modalSubnum2="
                metrica_proximosvencimientos_porcentaje + '%' || '0%'
              "
              :modalNumColspan="5"
              :modalSubnumColspan="3"
              :modalDataColspan="3"
              :modalData="proximosvencimientosInfo"
              :modalHeaders="[
                'Cliente',
                'PPU',
                'Codigo',
                'Fin Lease',
                'Días para Vencimiento Restantes',
              ]"
              :modalRowFields="[
                { key: 'CLIENTE', format: 'text' },
                { key: 'PPU', format: 'text' },
                { key: 'CODIGO', format: 'text' },
                { key: 'FIN_LEASE', format: 'date' },
                { key: 'DIAS_VENCIMIENTO', format: 'number' },
              ]"
              :modalExportDisabled="false"
              :modalEnableFilters="[true, true, true, true, true]"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Se importan las librerías y componentes necesarios.
import { computed, watch, ref, onBeforeMount } from "vue";
import Tabla from "../components/Tabla_metricas.vue";
import Boton from "../components/Boton.vue";
import SideMenu from "../components/SideMenu.vue";
import ButtonsMenu from "../components/Menu.vue";
import { useClientStore } from "@/stores/clientStore";
import { useSupStore } from "@/stores/supStore";
import useUserStore from "@/stores/userStore";

// Se importan las funciones necesarias para obtener los datos de las métricas de los supervisores.
import {
  getReporteMetricaSupervisorCPA,
  getReporteMetricaSupervisorMantenciones4D,
  getReporteMetricaSupervisorMantencionesEST,
  getReporteMetricaSupervisorRevisionesTecnicas,
  getReporteMetricaSupervisorRevisionesGases,
  getReporteMetricaSupervisorReemplazos,
  getReporteMetricaSupervisorUnidadesNuevas,
  getReporteMetricaSupervisorProximosVencimientos,
} from "@/api/metricassupervisor";

// Se importan las funciones necesarias para obtener los datos de las métricas de los clientes.
import {
  getReporteMetricaSupervisorCPACliente,
  getReporteMetricaSupervisorMantenciones4DCliente,
  getReporteMetricaSupervisorMantencionesESTCliente,
  getReporteMetricaSupervisorRevisionesTecnicasCliente,
  getReporteMetricaSupervisorRevisionesGasesCliente,
  getReporteMetricaSupervisorReemplazosCliente,
  getReporteMetricaSupervisorUnidadesNuevasCliente,
  getReporteMetricaSupervisorProximosVencimientosCliente,
} from "@/api/metricassupervisorcliente";

// Se importan las funciones necesarias para obtener los datos de las métricas generales.
import {
  getReporteMetricaGeneralCPA,
  getReporteMetricaGeneralMantenciones4D,
  getReporteMetricaGeneralMantencionesEST,
  getReporteMetricaGeneralRevisionesTecnicas,
  getReporteMetricaGeneralRevisionesGases,
  getReporteMetricaGeneralReemplazos,
  getReporteMetricaGeneralUnidadesNuevas,
  getReporteMetricaGeneralProximosVencimientos,
} from "@/api/metricasgeneral";

const userStore = useUserStore();
const userRole = computed(() => userStore.user?.rol);
const userSupervisorId = computed(() => userStore.user?.id);

// Se obtiene la información del cliente seleccionado.
const clientStore = useClientStore();
const selectedClient = computed(() => clientStore.selectedClient);

// Se obtiene la información del supervisor seleccionado.
const supStore = useSupStore();
const selectedSup = computed(() => supStore.selectedSup);

// Se inicializan las variables necesarias para almacenar la información de las métricas.
const cpaInfo = ref([]);
const mantenciones4dInfo = ref([]);
const mantencionesestInfo = ref([]);
const revisionestecnicasInfo = ref([]);
const revisionesgasesInfo = ref([]);
const reemplazosInfo = ref([]);
const unidadesnuevasInfo = ref([]);
const proximosvencimientosInfo = ref([]);

const metrica_cpa_porcentaje = ref(0);
const metrica_cpa_num = ref(0);
const metrica_mantenciones4d_porcentaje = ref(0);
const metrica_mantenciones4d_num = ref(0);
const metrica_mantencionesest_porcentaje = ref(0);
const metrica_mantencionesest_num = ref(0);
const metrica_revisionestecnicas_porcentaje = ref(0);
const metrica_revisionestecnicas_num = ref(0);
const metrica_revisionesgases_porcentaje = ref(0);
const metrica_revisionesgases_num = ref(0);
const metrica_reemplazos_porcentaje = ref(0);
const metrica_reemplazos_num = ref(0);
const metrica_unidadesnuevas_promedio = ref(0);
const metrica_unidadesnuevas_num = ref(0);
const metrica_proximosvencimientos_porcentaje = ref(0);
const metrica_proximosvencimientos_num = ref(0);

// Se obtiene la fecha actual.
const fecha = new Date().toLocaleDateString("en-CA");

// Se establece el valor del número de cliente
const Numero_cliente = computed(() => {
  if (!selectedClient.value) {
    return null;
  }
  const value = selectedClient.value.unikey_CL || null;
  return value;
});

// Se establece el valor de la variable Sup_Cod_Cartera según el rol del usuario.
const Sup_Cod_Cartera = computed(() => {
  if (userRole.value === "manager") {
    return userSupervisorId.value || null;
  } else if (userRole.value === "admin") {
    return selectedSup.value?.unikey_SU || null;
  }
  return null;
});

// Función para resetear las variables de las métricas.
const resetMetrics = () => {
  cpaInfo.value = [];
  mantenciones4dInfo.value = [];
  mantencionesestInfo.value = [];
  revisionestecnicasInfo.value = [];
  revisionesgasesInfo.value = [];
  reemplazosInfo.value = [];
  unidadesnuevasInfo.value = [];
  proximosvencimientosInfo.value = [];
  metrica_cpa_porcentaje.value = 0;
  metrica_cpa_num.value = 0;
  metrica_mantenciones4d_porcentaje.value = 0;
  metrica_mantenciones4d_num.value = 0;
  metrica_mantencionesest_porcentaje.value = 0;
  metrica_mantencionesest_num.value = 0;
  metrica_revisionestecnicas_porcentaje.value = 0;
  metrica_revisionestecnicas_num.value = 0;
  metrica_revisionesgases_porcentaje.value = 0;
  metrica_revisionesgases_num.value = 0;
  metrica_reemplazos_porcentaje.value = 0;
  metrica_reemplazos_num.value = 0;
  metrica_unidadesnuevas_promedio.value = 0;
  metrica_unidadesnuevas_num.value = 0;
  metrica_proximosvencimientos_porcentaje.value = 0;
  metrica_proximosvencimientos_num.value = 0;
};

// Función para cargar todas las métricas.
const loadAllMetrics = async () => {
  if (!Sup_Cod_Cartera.value || !Numero_cliente.value) {
    return;
  }
  resetMetrics();

  // Se define la variable isGeneral para determinar si se deben cargar las métricas generales o de un supervisor.
  const isGeneral = Sup_Cod_Cartera.value === 100;

  try {
    // En caso de que sea un administrador, se cargan las métricas generales, en otro caso se cargan las métricas del supervisor.
    if (isGeneral) {
      await Promise.all([
        loadGeneralCPAData(),
        loadGeneralMantenciones4DData(),
        loadGeneralMantencionesESTData(),
        loadGeneralRevisionesTecnicasData(),
        loadGeneralRevisionesGasesData(),
        loadGeneralReemplazosData(),
        loadGeneralUnidadesNuevasData(),
        loadGeneralProximosVencimientosData(),
      ]);
    } else if (Sup_Cod_Cartera.value != 100 && Numero_cliente.value === 100) {
      await Promise.all([
        loadSupervisorCPAData(),
        loadSupervisorMantenciones4DData(),
        loadSupervisorMantencionesESTData(),
        loadSupervisorRevisionesTecnicasData(),
        loadSupervisorRevisionesGasesData(),
        loadSupervisorReemplazosData(),
        loadSupervisorUnidadesNuevasData(),
        loadSupervisorProximosVencimientosData(),
      ]);
    } else if (Sup_Cod_Cartera.value != 100 && Numero_cliente.value != 100) {
      await Promise.all([
        loadClienteCPAData(),
        loadClienteMantenciones4DData(),
        loadClienteMantencionesESTData(),
        loadClienteRevisionesTecnicasData(),
        loadClienteRevisionesGasesData(),
        loadClienteReemplazosData(),
        loadClienteUnidadesNuevasData(),
        loadClienteProximosVencimientosData(),
      ]);
    }
  } catch (error) {
    console.error("Error al cargar todas las métricas:", error);
  }
};

// Función para obtener la información (al detalle, numérica y porcentual) de las métricas de CPA generales.
const loadGeneralCPAData = async () => {
  try {
    const response = await getReporteMetricaGeneralCPA(
      fecha,
      "CPA",
      Sup_Cod_Cartera.value
    );
    const reporte = response?.datametricacpageneral;
    if (reporte) {
      metrica_cpa_porcentaje.value = reporte.PORCENTAJE_CPA;
      metrica_cpa_num.value = reporte.CPA_NUM;
      cpaInfo.value = reporte.datametricacpageneral.map((item) => ({
        ...item,
        CPA: formatCurrency(item.CPA),
        Cargos: formatCurrency(item.Cargos),
        FECHA_APERTURA: formatDate(item.FECHA_APERTURA),
        DIAS_APERTURADO: formatNumber(item.DIAS_APERTURADO),
      }));
    } else {
      metrica_cpa_porcentaje.value = "no data";
      metrica_cpa_num.value = "no data";
    }
  } catch (error) {
    console.error("Error al cargar los datos de CPA (general):", error.message);
  }
};

// Función para obtener la información (al detalle, numérica y porcentual) de las métricas de Mantenciones 4D generales.
const loadGeneralMantenciones4DData = async () => {
  try {
    const response = await getReporteMetricaGeneralMantenciones4D(
      fecha,
      "Mantenciones4D",
      Sup_Cod_Cartera.value
    );
    const reporte = response?.datametricamantenciones4dgeneral;
    if (reporte) {
      metrica_mantenciones4d_porcentaje.value = reporte.PORCENTAJE_4D;
      metrica_mantenciones4d_num.value = reporte.UNIDADES_PENDIENTES_4D;
      mantenciones4dInfo.value = reporte.datametricamantenciones4dgeneral.map(
        (item) => ({
          ...item,
          KM_4D: formatNumber(item.KM_4D),
          KM_MP: formatNumber(item.KM_MP),
          KM_PROX_MP: formatNumber(item.KM_PROX_MP),
          FECHA_MP: formatDate(item.FECHA_MP),
          EXCESO_4D: formatNumber(item.EXCESO_4D),
        })
      );
    } else {
      metrica_mantenciones4d_porcentaje.value = "no data";
      metrica_mantenciones4d_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de Mantenciones 4D (general):",
      error.message
    );
  }
};

// Función para obtener la información (al detalle, numérica y porcentual) de las métricas de Mantenciones EST generales.
const loadGeneralMantencionesESTData = async () => {
  try {
    const response = await getReporteMetricaGeneralMantencionesEST(
      fecha,
      "MantencionesEST",
      Sup_Cod_Cartera.value
    );
    const reporte = response?.datametricamantencionesestgeneral;
    if (reporte) {
      metrica_mantencionesest_porcentaje.value = reporte.PORCENTAJE_EST;
      metrica_mantencionesest_num.value = reporte.UNIDADES_PENDIENTES_EST;
      mantencionesestInfo.value = reporte.datametricamantencionesestgeneral.map(
        (item) => ({
          ...item,
          KM_4D: formatNumber(item.KM_4D),
          KMS_EST: formatNumber(item.KMS_EST),
          KM_MP: formatNumber(item.KM_MP),
          KM_PROX_MP: formatNumber(item.KM_PROX_MP),
          FECHA_MP: formatDate(item.FECHA_MP),
          EXCESO_EST: formatNumber(item.EXCESO_EST),
        })
      );
    } else {
      metrica_mantencionesest_porcentaje.value = "no data";
      metrica_mantencionesest_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de Mantenciones EST (general):",
      error.message
    );
  }
};

// Función para obtener la información (al detalle, numérica y porcentual) de las métricas de Revisiones Técnicas generales.
const loadGeneralRevisionesTecnicasData = async () => {
  try {
    const response = await getReporteMetricaGeneralRevisionesTecnicas(
      fecha,
      "RevisionesTecnicas",
      Sup_Cod_Cartera.value
    );
    const reporte = response?.datametricarevisionestecnicasgeneral;
    if (reporte) {
      metrica_revisionestecnicas_porcentaje.value =
        reporte.PORCENTAJE_REVISIONES_Tecnicas_GENERAL;
      metrica_revisionestecnicas_num.value =
        reporte.REVISIONES_Tecnicas_GENERAL_Num;
      revisionestecnicasInfo.value =
        reporte.datametricarevisionestecnicasgeneral.map((item) => ({
          ...item,
          Rev_TECNICA: formatDate(item.Rev_TECNICA),
        }));
    } else {
      metrica_revisionestecnicas_porcentaje.value = "no data";
      metrica_revisionestecnicas_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de Revisiones Técnicas (general):",
      error.message
    );
  }
};

// Función para obtener la información (al detalle, numérica y porcentual) de las métricas de Revisiones Gases generales.
const loadGeneralRevisionesGasesData = async () => {
  try {
    const response = await getReporteMetricaGeneralRevisionesGases(
      fecha,
      "RevisionesGases",
      Sup_Cod_Cartera.value
    );
    const reporte = response?.datametricarevisionesgasesgeneral;
    if (reporte) {
      metrica_revisionesgases_porcentaje.value =
        reporte.PORCENTAJE_REVISIONES_Gases_GENERAL;
      metrica_revisionesgases_num.value = reporte.REVISIONES_Gases_GENERAL_Num;
      revisionesgasesInfo.value = reporte.datametricarevisionesgasesgeneral.map(
        (item) => ({
          ...item,
          Rev_GASES: formatDate(item.Rev_GASES),
        })
      );
    } else {
      metrica_revisionesgases_porcentaje.value = "no data";
      metrica_revisionesgases_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de Revisiones Gases (general):",
      error.message
    );
  }
};

// Función para obtener la información (al detalle, numérica y promedio) de las métricas de Reemplazos generales.
const loadGeneralReemplazosData = async () => {
  try {
    const response = await getReporteMetricaGeneralReemplazos(
      fecha,
      "Reemplazos",
      Sup_Cod_Cartera.value
    );

    const reporte = response?.datametricareemplazosgeneral;
    if (reporte) {
      metrica_reemplazos_porcentaje.value =
        reporte.PROMEDIO_DIAS_REEMPLAZO_General;
      metrica_reemplazos_num.value = reporte.Reemplazos_cierre_menor_5_dias;
      reemplazosInfo.value = reporte.datametricareemplazosgeneral.map(
        (item) => ({
          ...item,
          FECHA_REEMPLAZO: formatDate(item.FECHA_REEMPLAZO),
          DIAS_REEMPLAZO: formatNumber(item.DIAS_REEMPLAZO),
          Dias_Cierre_OT: formatNumber(item.Dias_Cierre_OT),
        })
      );
    } else {
      metrica_reemplazos_porcentaje.value = "no data";
      metrica_reemplazos_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de Reemplazos (general):",
      error.message
    );
  }
};

// Función para obtener la información (al detalle, numérica y promedio) de las métricas de Unidades Nuevas generales.
const loadGeneralUnidadesNuevasData = async () => {
  try {
    const response = await getReporteMetricaGeneralUnidadesNuevas(
      fecha,
      "UnidadesNuevas",
      Sup_Cod_Cartera.value
    );
    const reporte = response?.datametricaunidadesnuevasgeneral;
    if (reporte) {
      metrica_unidadesnuevas_promedio.value = reporte.PROMEDIO_DIAS_DISPONIBLE;
      metrica_unidadesnuevas_num.value = reporte.UNIDADES_Nuevas;
      unidadesnuevasInfo.value = reporte.datametricaunidadesnuevasgeneral.map(
        (item) => ({
          ...item,
          FECHA_DISPONIBLE: formatDate(item.FECHA_DISPONIBLE),
          FECHA_ALQUILADO: formatDate(item.FECHA_ALQUILADO),
          DIAS_DISPONIBLE: formatNumber(item.DIAS_DISPONIBLE),
        })
      );
    } else {
      metrica_unidadesnuevas_promedio.value = "no data";
      metrica_unidadesnuevas_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de Unidades Nuevas (general):",
      error.message
    );
  }
};

// Función para obtener la información (al detalle, numérica y porcentual) de las métricas de Próximos Vencimientos generales.
const loadGeneralProximosVencimientosData = async () => {
  try {
    const response = await getReporteMetricaGeneralProximosVencimientos(
      fecha,
      "ProximosVencimientos",
      Sup_Cod_Cartera.value
    );
    const reporte = response?.datametricaproximosvencimientosgeneral;
    if (reporte) {
      metrica_proximosvencimientos_porcentaje.value =
        reporte.PORCENTAJE_PROXIMOS_VENCIMIENTOS;
      metrica_proximosvencimientos_num.value = reporte.PROXIMOS_VENCIMIENTOS;
      proximosvencimientosInfo.value =
        reporte.datametricaproximosvencimientosgeneral.map((item) => ({
          ...item,
          FIN_LEASE: formatDate(item.FIN_LEASE),
          DIAS_VENCIMIENTO: formatNumber(item.DIAS_VENCIMIENTO),
        }));
    } else {
      metrica_proximosvencimientos_porcentaje.value = "no data";
      metrica_proximosvencimientos_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de Próximos Vencimientos (general):",
      error.message
    );
  }
};

// Función para obtener la información (al detalle, numérica y porcentual) de las métricas de CPA de un supervisor.
const loadSupervisorCPAData = async () => {
  try {
    const response = await getReporteMetricaSupervisorCPA(
      fecha,
      "CPA",
      Sup_Cod_Cartera.value
    );
    const reporte = response?.datametricacpasupervisor;
    if (reporte) {
      metrica_cpa_porcentaje.value = reporte.PORCENTAJE_CPA;
      metrica_cpa_num.value = reporte.CPA_NUM;
      cpaInfo.value = reporte.datametricacpasupervisor.map((item) => ({
        ...item,
        CPA: formatCurrency(item.CPA),
        Cargos: formatCurrency(item.Cargos),
        FECHA_APERTURA: formatDate(item.FECHA_APERTURA),
        DIAS_APERTURADO: formatNumber(item.DIAS_APERTURADO),
      }));
    } else {
      metrica_cpa_porcentaje.value = "no data";
      metrica_cpa_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de CPA (supervisor):",
      error.message
    );
  }
};

// Función para obtener la información (al detalle, numérica y porcentual) de las métricas de Mantenciones 4D de un supervisor.
const loadSupervisorMantenciones4DData = async () => {
  try {
    const response = await getReporteMetricaSupervisorMantenciones4D(
      fecha,
      "Mantenciones4D",
      Sup_Cod_Cartera.value
    );
    const reporte = response?.datametricamantenciones4dsupervisor;
    if (reporte) {
      metrica_mantenciones4d_porcentaje.value = reporte.PORCENTAJE_4D;
      metrica_mantenciones4d_num.value = reporte.UNIDADES_PENDIENTES_4D;
      mantenciones4dInfo.value =
        reporte.datametricamantenciones4dsupervisor.map((item) => ({
          ...item,
          KM_4D: formatNumber(item.KM_4D),
          KM_MP: formatNumber(item.KM_MP),
          KM_PROX_MP: formatNumber(item.KM_PROX_MP),
          FECHA_MP: formatDate(item.FECHA_MP),
          EXCESO_4D: formatNumber(item.EXCESO_4D),
        }));
    } else {
      metrica_mantenciones4d_porcentaje.value = "no data";
      metrica_mantenciones4d_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de Mantenciones 4D (supervisor):",
      error.message
    );
  }
};

// Función para obtener la información (al detalle, numérica y porcentual) de las métricas de Mantenciones EST de un supervisor.
const loadSupervisorMantencionesESTData = async () => {
  try {
    const response = await getReporteMetricaSupervisorMantencionesEST(
      fecha,
      "MantencionesEST",
      Sup_Cod_Cartera.value
    );
    const reporte = response?.datametricamantencionesestsupervisor;
    if (reporte) {
      metrica_mantencionesest_porcentaje.value = reporte.PORCENTAJE_EST;
      metrica_mantencionesest_num.value = reporte.UNIDADES_PENDIENTES_EST;
      mantencionesestInfo.value =
        reporte.datametricamantencionesestsupervisor.map((item) => ({
          ...item,
          KM_4D: formatNumber(item.KM_4D),
          KMS_EST: formatNumber(item.KMS_EST),
          KM_MP: formatNumber(item.KM_MP),
          KM_PROX_MP: formatNumber(item.KM_PROX_MP),
          FECHA_MP: formatDate(item.FECHA_MP),
          EXCESO_EST: formatNumber(item.EXCESO_EST),
        }));
    } else {
      metrica_mantencionesest_porcentaje.value = "no data";
      metrica_mantencionesest_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de Mantenciones EST (supervisor):",
      error.message
    );
  }
};

// Función para obtener la información (al detalle, numérica y porcentual) de las métricas de Revisiones Técnicas de un supervisor.
const loadSupervisorRevisionesTecnicasData = async () => {
  try {
    const response = await getReporteMetricaSupervisorRevisionesTecnicas(
      fecha,
      "RevisionesTecnicas",
      Sup_Cod_Cartera.value
    );
    const reporte = response?.datametricarevisionestecnicassupervisor;
    if (reporte) {
      metrica_revisionestecnicas_porcentaje.value =
        reporte.PORCENTAJE_REVISIONES_Tecnicas_SUPERVISOR;
      metrica_revisionestecnicas_num.value =
        reporte.REVISIONES_Tecnicas_SUPERVISOR_Num;
      revisionestecnicasInfo.value =
        reporte.datametricarevisionestecnicassupervisor.map((item) => ({
          ...item,
          Rev_TECNICA: formatDate(item.Rev_TECNICA),
        }));
    } else {
      metrica_revisionestecnicas_porcentaje.value = "no data";
      metrica_revisionestecnicas_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de Revisiones Técnicas (supervisor):",
      error.message
    );
  }
};

// Función para obtener la información (al detalle, numérica y porcentual) de las métricas de Revisiones Gases de un supervisor.
const loadSupervisorRevisionesGasesData = async () => {
  try {
    const response = await getReporteMetricaSupervisorRevisionesGases(
      fecha,
      "RevisionesGases",
      Sup_Cod_Cartera.value
    );
    const reporte = response?.datametricarevisionesgasessupervisor;
    if (reporte) {
      metrica_revisionesgases_porcentaje.value =
        reporte.PORCENTAJE_REVISIONES_Gases_SUPERVISOR;
      metrica_revisionesgases_num.value =
        reporte.REVISIONES_Gases_SUPERVISOR_Num;
      revisionesgasesInfo.value =
        reporte.datametricarevisionesgasessupervisor.map((item) => ({
          ...item,
          Rev_GASES: formatDate(item.Rev_GASES),
        }));
    } else {
      metrica_revisionesgases_porcentaje.value = "no data";
      metrica_revisionesgases_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de Revisiones Gases (supervisor):",
      error.message
    );
  }
};

// Función para obtener la información (al detalle, numérica y promedio) de las métricas de Reemplazos de un supervisor.
const loadSupervisorReemplazosData = async () => {
  try {
    const response = await getReporteMetricaSupervisorReemplazos(
      fecha,
      "Reemplazos",
      Sup_Cod_Cartera.value
    );
    const reporte = response?.datametricareemplazossupervisor;
    if (reporte) {
      metrica_reemplazos_porcentaje.value =
        reporte.PROMEDIO_DIAS_REEMPLAZO_SUPERVISOR;
      metrica_reemplazos_num.value = reporte.Reemplazos_cierre_menor_5_dias;
      reemplazosInfo.value = reporte.datametricareemplazossupervisor.map(
        (item) => ({
          ...item,
          FECHA_REEMPLAZO: formatDate(item.FECHA_REEMPLAZO),
          DIAS_REEMPLAZO: formatNumber(item.DIAS_REEMPLAZO),
          Dias_Cierre_OT: formatNumber(item.Dias_Cierre_OT),
        })
      );
    } else {
      metrica_reemplazos_porcentaje.value = "no data";
      metrica_reemplazos_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de Reemplazos (supervisor):",
      error.message
    );
  }
};

// Función para obtener la información (al detalle, numérica y promedio) de las métricas de Unidades Nuevas de un supervisor.
const loadSupervisorUnidadesNuevasData = async () => {
  try {
    const response = await getReporteMetricaSupervisorUnidadesNuevas(
      fecha,
      "UnidadesNuevas",
      Sup_Cod_Cartera.value
    );

    const reporte = response?.datametricaunidadesnuevassupervisor;
    if (reporte) {
      metrica_unidadesnuevas_promedio.value = reporte.PROMEDIO_DIAS_DISPONIBLE;
      metrica_unidadesnuevas_num.value = reporte.UNIDADES_Nuevas;
      unidadesnuevasInfo.value =
        reporte.datametricaunidadesnuevassupervisor.map((item) => ({
          ...item,
          FECHA_DISPONIBLE: formatDate(item.FECHA_DISPONIBLE),
          FECHA_ALQUILADO: formatDate(item.FECHA_ALQUILADO),
          DIAS_DISPONIBLE: formatNumber(item.DIAS_DISPONIBLE),
        }));
    } else {
      metrica_unidadesnuevas_promedio.value = "no data";
      metrica_unidadesnuevas_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de Unidades Nuevas (supervisor):",
      error.message
    );
  }
};

// Función para obtener la información (al detalle, numérica y porcentual) de las métricas de Próximos Vencimientos de un supervisor.
const loadSupervisorProximosVencimientosData = async () => {
  try {
    const response = await getReporteMetricaSupervisorProximosVencimientos(
      fecha,
      "ProximosVencimientos",
      Sup_Cod_Cartera.value
    );
    const reporte = response?.datametricaproximosvencimientossupervisor;
    if (reporte) {
      metrica_proximosvencimientos_porcentaje.value =
        reporte.PORCENTAJE_PROXIMOS_VENCIMIENTOS;
      metrica_proximosvencimientos_num.value = reporte.PROXIMOS_VENCIMIENTOS;
      proximosvencimientosInfo.value =
        reporte.datametricaproximosvencimientossupervisor.map((item) => ({
          ...item,
          FIN_LEASE: formatDate(item.FIN_LEASE),
          DIAS_VENCIMIENTO: formatNumber(item.DIAS_VENCIMIENTO),
        }));
    } else {
      metrica_proximosvencimientos_porcentaje.value = "no data";
      metrica_proximosvencimientos_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de Próximos Vencimientos (supervisor):",
      error.message
    );
  }
};

// Función para obtener la información (al detalle, numérica y porcentual) de las métricas de CPA de un cliente.
const loadClienteCPAData = async () => {
  try {
    const response = await getReporteMetricaSupervisorCPACliente(
      fecha,
      "CPA",
      Sup_Cod_Cartera.value,
      Numero_cliente.value
    );
    const reporte = response?.datametricacpasupervisorcliente;
    if (reporte) {
      metrica_cpa_porcentaje.value = reporte.PORCENTAJE_CPA;
      metrica_cpa_num.value = reporte.CPA_NUM;
      cpaInfo.value = reporte.datametricacpasupervisorcliente.map((item) => ({
        ...item,

        CPA: formatCurrency(item.CPA),
        Cargos: formatCurrency(item.Cargos),
        FECHA_APERTURA: formatDate(item.FECHA_APERTURA),
        DIAS_APERTURADO: formatNumber(item.DIAS_APERTURADO),
      }));
    } else {
      metrica_cpa_porcentaje.value = "no data";
      metrica_cpa_num.value = "no data";
    }
  } catch (error) {
    console.error("Error al cargar los datos de CPA (cliente):", error.message);
  }
};

// Función para obtener la información (al detalle, numérica y porcentual) de las métricas de Mantenciones 4D de un cliente.
const loadClienteMantenciones4DData = async () => {
  try {
    const response = await getReporteMetricaSupervisorMantenciones4DCliente(
      fecha,
      "Mantenciones4D",
      Sup_Cod_Cartera.value,
      Numero_cliente.value
    );
    const reporte = response?.datametricamantenciones4dsupervisorcliente;
    if (reporte) {
      metrica_mantenciones4d_porcentaje.value = reporte.PORCENTAJE_4D;
      metrica_mantenciones4d_num.value = reporte.UNIDADES_PENDIENTES_4D;
      mantenciones4dInfo.value =
        reporte.datametricamantenciones4dsupervisorcliente.map((item) => ({
          ...item,
          KM_4D: formatNumber(item.KM_4D),
          KM_MP: formatNumber(item.KM_MP),
          KM_PROX_MP: formatNumber(item.KM_PROX_MP),
          FECHA_MP: formatDate(item.FECHA_MP),
          EXCESO_4D: formatNumber(item.EXCESO_4D),
        }));
    } else {
      metrica_mantenciones4d_porcentaje.value = "no data";
      metrica_mantenciones4d_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de Mantenciones 4D (cliente):",
      error.message
    );
  }
};

// Función para obtener la información (al detalle, numérica y porcentual) de las métricas de Mantenciones EST de un cliente.
const loadClienteMantencionesESTData = async () => {
  try {
    const response = await getReporteMetricaSupervisorMantencionesESTCliente(
      fecha,
      "MantencionesEST",
      Sup_Cod_Cartera.value,
      Numero_cliente.value
    );
    const reporte = response?.datametricamantencionesestsupervisorcliente;
    if (reporte) {
      metrica_mantencionesest_porcentaje.value = reporte.PORCENTAJE_EST;
      metrica_mantencionesest_num.value = reporte.UNIDADES_PENDIENTES_EST;
      mantencionesestInfo.value =
        reporte.datametricamantencionesestsupervisorcliente.map((item) => ({
          ...item,
          KM_4D: formatNumber(item.KM_4D),
          KMS_EST: formatNumber(item.KMS_EST),
          KM_MP: formatNumber(item.KM_MP),
          KM_PROX_MP: formatNumber(item.KM_PROX_MP),
          FECHA_MP: formatDate(item.FECHA_MP),
          EXCESO_EST: formatNumber(item.EXCESO_EST),
        }));
    } else {
      metrica_mantencionesest_porcentaje.value = "no data";
      metrica_mantencionesest_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de Mantenciones EST (cliente):",
      error.message
    );
  }
};

// Función para obtener la información (al detalle, numérica y porcentual) de las métricas de Revisiones Técnicas de un cliente.
const loadClienteRevisionesTecnicasData = async () => {
  try {
    const response = await getReporteMetricaSupervisorRevisionesTecnicasCliente(
      fecha,
      "RevisionesTecnicas",
      Sup_Cod_Cartera.value,
      Numero_cliente.value
    );
    const reporte = response?.datametricarevisionestecnicassupervisorcliente;
    if (reporte) {
      metrica_revisionestecnicas_porcentaje.value =
        reporte.PORCENTAJE_REVISIONES_Tecnicas_SUPERVISOR;
      metrica_revisionestecnicas_num.value =
        reporte.REVISIONES_Tecnicas_SUPERVISOR_Num;
      revisionestecnicasInfo.value =
        reporte.datametricarevisionestecnicassupervisorcliente.map((item) => ({
          ...item,
          Rev_TECNICA: formatDate(item.Rev_TECNICA),
        }));
    } else {
      metrica_revisionestecnicas_porcentaje.value = "no data";
      metrica_revisionestecnicas_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de Revisiones Técnicas (cliente):",
      error.message
    );
  }
};

// Función para obtener la información (al detalle, numérica y porcentual) de las métricas de Revisiones Gases de un cliente.
const loadClienteRevisionesGasesData = async () => {
  try {
    const response = await getReporteMetricaSupervisorRevisionesGasesCliente(
      fecha,
      "RevisionesGases",
      Sup_Cod_Cartera.value,
      Numero_cliente.value
    );
    const reporte = response?.datametricarevisionesgasessupervisorcliente;
    if (reporte) {
      metrica_revisionesgases_porcentaje.value =
        reporte.PORCENTAJE_REVISIONES_Gases_SUPERVISOR;
      metrica_revisionesgases_num.value =
        reporte.REVISIONES_Gases_SUPERVISOR_Num;
      revisionesgasesInfo.value =
        reporte.datametricarevisionesgasessupervisorcliente.map((item) => ({
          ...item,
          Rev_GASES: formatDate(item.Rev_GASES),
        }));
    } else {
      metrica_revisionesgases_porcentaje.value = "no data";
      metrica_revisionesgases_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de Revisiones Gases (supervisor):",
      error.message
    );
  }
};

// Función para obtener la información (al detalle, numérica y promedio) de las métricas de Reemplazos de un cliente.
const loadClienteReemplazosData = async () => {
  try {
    const response = await getReporteMetricaSupervisorReemplazosCliente(
      fecha,
      "Reemplazos",
      Sup_Cod_Cartera.value,
      Numero_cliente.value
    );
    const reporte = response?.datametricareemplazossupervisorcliente;
    if (reporte) {
      metrica_reemplazos_porcentaje.value =
        reporte.PROMEDIO_DIAS_REEMPLAZO_SUPERVISOR;
      metrica_reemplazos_num.value = reporte.Reemplazos_cierre_menor_5_dias;
      reemplazosInfo.value = reporte.datametricareemplazossupervisorcliente.map(
        (item) => ({
          ...item,
          FECHA_REEMPLAZO: formatDate(item.FECHA_REEMPLAZO),
          DIAS_REEMPLAZO: formatNumber(item.DIAS_REEMPLAZO),
          Dias_Cierre_OT: formatNumber(item.Dias_Cierre_OT),
        })
      );
    } else {
      metrica_reemplazos_porcentaje.value = "no data";
      metrica_reemplazos_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de Reemplazos (cliente):",
      error.message
    );
  }
};

// Función para obtener la información (al detalle, numérica y promedio) de las métricas de Unidades Nuevas de un cliente.
const loadClienteUnidadesNuevasData = async () => {
  try {
    const response = await getReporteMetricaSupervisorUnidadesNuevasCliente(
      fecha,
      "UnidadesNuevas",
      Sup_Cod_Cartera.value,
      Numero_cliente.value
    );

    const reporte = response?.datametricaunidadesnuevassupervisorcliente;
    if (reporte) {
      metrica_unidadesnuevas_promedio.value = reporte.PROMEDIO_DIAS_DISPONIBLE;
      metrica_unidadesnuevas_num.value = reporte.UNIDADES_Nuevas;
      unidadesnuevasInfo.value =
        reporte.datametricaunidadesnuevassupervisorcliente.map((item) => ({
          ...item,
          FECHA_DISPONIBLE: formatDate(item.FECHA_DISPONIBLE),
          FECHA_ALQUILADO: formatDate(item.FECHA_ALQUILADO),
          DIAS_DISPONIBLE: formatNumber(item.DIAS_DISPONIBLE),
        }));
    } else {
      metrica_unidadesnuevas_promedio.value = "no data";
      metrica_unidadesnuevas_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de Unidades Nuevas (cliente):",
      error.message
    );
  }
};

// Función para obtener la información (al detalle, numérica y porcentual) de las métricas de Próximos Vencimientos de un supervisor.
const loadClienteProximosVencimientosData = async () => {
  try {
    const response =
      await getReporteMetricaSupervisorProximosVencimientosCliente(
        fecha,
        "ProximosVencimientos",
        Sup_Cod_Cartera.value,
        Numero_cliente.value
      );
    const reporte = response?.datametricaproximosvencimientossupervisorcliente;
    if (reporte) {
      metrica_proximosvencimientos_porcentaje.value =
        reporte.PORCENTAJE_PROXIMOS_VENCIMIENTOS;
      metrica_proximosvencimientos_num.value = reporte.PROXIMOS_VENCIMIENTOS;
      proximosvencimientosInfo.value =
        reporte.datametricaproximosvencimientossupervisorcliente.map(
          (item) => ({
            ...item,
            FIN_LEASE: formatDate(item.FIN_LEASE),
            DIAS_VENCIMIENTO: formatNumber(item.DIAS_VENCIMIENTO),
          })
        );
    } else {
      metrica_proximosvencimientos_porcentaje.value = "no data";
      metrica_proximosvencimientos_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de Próximos Vencimientos (cliente):",
      error.message
    );
  }
};

// Se define la variable isButtonEnabled para determinar si el botón de cargar métricas está habilitado.
const isButtonEnabled = computed(() => selectedClient.value);

// Funciones para dar formato a los números.
const formatNumber = (value) => {
  if (
    value === "-" ||
    value === "N/A" ||
    value === null ||
    value === "NULL" ||
    value === "null"
  ) {
    return "0";
  }
  const truncatedValue = Math.trunc(value * 100) / 100;
  return new Intl.NumberFormat().format(truncatedValue);
};

// Funciones para dar formato a las fechas y a los valores tipo fecha.
const formatDate = (value) => {
  if (!value) return "No disponible";
  return new Date(value).toLocaleDateString("es-CL");
};

// Funciones para dar formato a los valores tipo moneda.
const formatCurrency = (value) => {
  if (value === "Sin costo asociado" || value === "Sin cargo asociado") {
    return value;
  }
  return new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
  }).format(value);
};

onBeforeMount(() => {
  watch(
    [Sup_Cod_Cartera, Numero_cliente],
    async ([newSupValue, newClientValue]) => {
      resetMetrics();

      if (newSupValue && newClientValue) {
        await loadAllMetrics();
      }
    },
    { immediate: true }
  );

  // Si ambos valores están definidos, cargamos las métricas iniciales.
  if (Sup_Cod_Cartera.value && Numero_cliente.value) {
    loadAllMetrics();
  }
});

watch(
  [Sup_Cod_Cartera, Numero_cliente],
  async () => {
    resetMetrics();
  }
);

// Se utiliza onMounted para cargar las métricas al iniciar la página.
// onMounted(async () => {
//   await watchUntilReady(Sup_Cod_Cartera, Numero_cliente);
//   loadAllMetrics();
// });

// watch(selectedClient, (newClient, oldClient) => {
//   console.log("Cliente seleccionado cambió:", oldClient, "=>", newClient);
// });

// async function watchUntilReady(...refs) {
//   while (refs.some((ref) => !ref.value)) {
//     await new Promise((resolve) => setTimeout(resolve, 50));
//   }
// }
</script>

<!-- Se importa el css necesario para la estética correspondiente de la vista. -->
<style scoped>
@import "../css/home.css";
</style>
