// Se importa lo necesario para la correcta llamada a la base de datos (se importa api).
import { api } from "../services/api.js";

// Función para obtener la métrica CPA Cliente (detalle, numérica y porcentual).
export async function getReporteMetricaSupervisorCPACliente(
  fecha,
  metrica,
  id_supervisor,
  id_cliente
) {
  try {
    const response = await api.get(
      `/reportes/metrica_cpa_supervisor_cliente?fecha='${fecha}'&metrica=${metrica}&id_supervisor=${id_supervisor}&id_cliente=${id_cliente}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener la métrica CPA:", error);
    throw error;
  }
}

// Función para obtener la métrica Mantenciones4D Cliente (detalle, numérica y porcentual).
export async function getReporteMetricaSupervisorMantenciones4DCliente(
  fecha,
  metrica,
  id_supervisor,
  id_cliente
) {
  try {
    const response = await api.get(
      `/reportes/metrica_mantenciones4d_supervisor_cliente?fecha='${fecha}'&metrica=${metrica}&id_supervisor=${id_supervisor}&id_cliente=${id_cliente}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener el reporte Mantenciones4D:", error);
    throw error;
  }
}

// Función para obtener la métrica MantencionesEST Cliente (detalle, numérica y porcentual).
export async function getReporteMetricaSupervisorMantencionesESTCliente(
  fecha,
  metrica,
  id_supervisor,
  id_cliente
) {
  try {
    const response = await api.get(
      `/reportes/metrica_mantencionesest_supervisor_cliente?fecha='${fecha}'&metrica=${metrica}&id_supervisor=${id_supervisor}&id_cliente=${id_cliente}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener el reporte MantencionesEST:", error);
    throw error;
  }
}

// Función para obtener la métrica RevisionesTecnicas Cliente (detalle, numérica y porcentual).
export async function getReporteMetricaSupervisorRevisionesTecnicasCliente(
  fecha,
  metrica,
  id_supervisor,
  id_cliente
) {
  try {
    const response = await api.get(
      `/reportes/metrica_revisionestecnicas_supervisor_cliente?fecha='${fecha}'&metrica=${metrica}&id_supervisor=${id_supervisor}&id_cliente=${id_cliente}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener el reporte RevisionesTecnicas:", error);
    throw error;
  }
}

// Función para obtener la métrica RevisionesGases Cliente (detalle, numérica y porcentual).
export async function getReporteMetricaSupervisorRevisionesGasesCliente(
  fecha,
  metrica,
  id_supervisor,
  id_cliente
) {
  try {
    const response = await api.get(
      `/reportes/metrica_revisionesgases_supervisor_cliente?fecha='${fecha}'&metrica=${metrica}&id_supervisor=${id_supervisor}&id_cliente=${id_cliente}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener el reporte RevisionesGases:", error);
    throw error;
  }
}

// Función para obtener la métrica Reemplazos Cliente (detalle, numérica y promedio).
export async function getReporteMetricaSupervisorReemplazosCliente(
  fecha,
  metrica,
  id_supervisor,
  id_cliente
) {
  try {
    const response = await api.get(
      `/reportes/metrica_reemplazos_supervisor_cliente?fecha='${fecha}'&metrica=${metrica}&id_supervisor=${id_supervisor}&id_cliente=${id_cliente}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener el reporte Reemplazos:", error);
    throw error;
  }
}

// Función para obtener la métrica Unidades Nuevas Cliente (detalle, numérica y promedio).
export async function getReporteMetricaSupervisorUnidadesNuevasCliente(
  fecha,
  metrica,
  id_supervisor,
  id_cliente
) {
  try {
    const response = await api.get(
      `/reportes/metrica_unidadesnuevas_supervisor_cliente?fecha='${fecha}'&metrica=${metrica}&id_supervisor=${id_supervisor}&id_cliente=${id_cliente}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener la métrica Unidades Nuevas:", error);
    throw error;
  }
}

// Función para obtener la métrica Unidades Nuevas Cliente (detalle, numérica y porcentual).
export async function getReporteMetricaSupervisorProximosVencimientosCliente(
  fecha,
  metrica,
  id_supervisor,
  id_cliente
) {
  try {
    const response = await api.get(
      `/reportes/metrica_proximosvencimientos_supervisor_cliente?fecha='${fecha}'&metrica=${metrica}&id_supervisor=${id_supervisor}&id_cliente=${id_cliente}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener la métrica Próximos Vencimientos:", error);
    throw error;
  }
}
